<template>
    <BT-Blade-Items
        addBladeName="courier"
        bladeName="couriers"
        :bladesData="bladesData"
        canAdd
        :canCreate="false"
        :headers="[{ text: 'Company Name', value: 'seller.companyName', title: 1, searchable: true }]"
        hideActions
        navigation="couriers"
        title="Couriers"
        useServerPagination
        :defaultBladeWidth="350">
        <template v-slot:toolbar-right-far>
            <v-btn class="" small text :to="{ name: 'browse-couriers' }">
                <v-icon left small>mdi-store-search</v-icon>Find
            </v-btn>
        </template>
        <template v-slot:actionsRight>
            <v-btn
                class=""
                small
                icon
                @click="integrationToggle = !integrationToggle">
                <v-icon small>mdi-connection</v-icon>
            </v-btn>
        </template>
        <template v-slot:settings>
            <v-list-item :to="{ name: 'courier-requests' }">
                <v-list-item-icon>
                    <v-icon small>mdi-account-question</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Sent Requests</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'browse-couriers' }">
                <v-list-item-icon>
                    <v-icon small>mdi-card-search</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Browse</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
        <template v-slot:bottom>
            <BT-Sidebar-External-Integration
                navigation="couriers-fulfilling"
                :showToggle="integrationToggle"
                sidebarLabel="Sync With"
                syncNavigation="fulfiller-syncing">
                <template v-slot:listItem="{ item }">
                    <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                </template>
            </BT-Sidebar-External-Integration>
        </template>
        <template v-slot:itemActions="{ item }">
            <Customer-Link
                v-if="item.seller != null && item.seller.isManagedByAnotherAccount"
                :agreement="item"
                :managedAccountID="item != null ? item.seller.id : null" />
            <v-icon 
                v-else
                small
                title="Live Account" />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Couriers-Blade',
    components: {
        CustomerLink: () => import('~home/customers/Customer-Link.vue'),
        BTSidebarExternalIntegration: () => import('~components/BT-Sidebar-External-Integration.vue'),
    },
    data: function() {
        return {
            integrationToggle: false,
        }
    },
    props: {
        bladesData: null
    }
}
</script>